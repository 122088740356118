import * as ToastPrimitives from "@radix-ui/react-toast";
import { cva, type VariantProps } from "class-variance-authority";
import { Check, X } from "lucide-react";
import * as React from "react";

import { cn } from "@utils/tailwind";

const ToastProvider = ToastPrimitives.Provider;

const ToastViewport = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Viewport>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    className={cn(
      "tw-fixed tw-top-0 tw-z-[10000] tw-flex tw-max-h-screen tw-w-full tw-flex-col-reverse tw-gap-2 tw-p-4 sm:tw-bottom-auto sm:tw-right-0 sm:tw-top-0 sm:tw-flex-col md:tw-max-w-[420px]",
      className,
    )}
    {...props}
  />
));
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;

const toastVariants = cva(
  "tw-group tw-pointer-events-auto tw-relative tw-flex tw-gap-4 tw-w-full tw-items-center tw-space-x-4 tw-overflow-hidden tw-rounded-regular tw-border-solid tw-border tw-border-grey tw-py-5 tw-px-4 tw-shadow-regular tw-transition-all data-[swipe=cancel]:tw-translate-x-0 data-[swipe=end]:tw-translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:tw-translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:tw-transition-none data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out data-[swipe=end]:tw-animate-out data-[state=closed]:tw-fade-out-80 data-[state=closed]:tw-slide-out-to-right-full data-[state=open]:tw-slide-in-from-top-full data-[state=open]:sm:tw-slide-in-from-bottom-full",
  {
    variants: {
      variant: {
        default: "tw-bg-white tw-text-primary",
        success: "tw-bg-white tw-text-primary",
        error: "tw-bg-white tw-text-primary",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

const progressVariants = cva("", {
  variants: {
    variant: {
      default: "tw-bg-blue",
      success: "tw-bg-pistachio",
      error: "tw-bg-raspberry",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

const iconVariants = cva("tw-w-4 tw-min-w-4 tw-h-4 tw-min-h-4", {
  variants: {
    variant: {
      default: "tw-text-blue",
      success: "tw-text-pistachio",
      error: "tw-text-raspberry",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

const addSuccessText = window.gettext(" has just been added to your $$.");
const getAddSuccessDescription = (title: string) => {
  const [part1, part2] = addSuccessText.split("$$");
  return (
    <>
      <span className="tw-font-medium">{title} </span>
      {part1}
      <a href="/deadline-tracker/">{window.gettext("Deadline Tracker")}</a>
      {part2}
    </>
  );
};

const removeSuccessText = window.gettext(
  " has just been removed from your $$.",
);
const getRemoveSuccessDescription = (title: string) => {
  const [part1, part2] = removeSuccessText.split("$$");
  return (
    <>
      <span className="tw-font-medium">{title} </span>
      {part1}
      <a href="/deadline-tracker/">{window.gettext("Deadline Tracker")}</a>
      {part2}
    </>
  );
};

const getErrorDescription = (statusText: string) => (
  <>
    {window.gettext("An error occurred while adding to your Deadline Tracker.")}
    <br />
    <strong>Status:</strong> {statusText}
  </>
);

const Toast = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root> &
    VariantProps<typeof toastVariants>
>(({ className, variant, ...props }, ref) => {
  const [paused, setPaused] = React.useState(false);

  const duration = props.duration || 5000;

  return (
    <ToastPrimitives.Root
      ref={ref}
      duration={duration}
      onPause={() => setPaused(true)}
      onResume={() => setPaused(false)}
      className={cn(
        toastVariants({ variant }),
        className,
        "tw-relative tw-space-x-0 tw-motion-translate-x-in-100 tw-motion-opacity-in-0 tw-motion-duration-[300ms] tw-motion-ease-spring-smooth data-[state=closed]:-tw-motion-translate-y-out-100 data-[state=closed]:tw-motion-opacity-out-0",
      )}
      {...props}
    >
      <ToastIcon variant={variant} />
      {props.children}
      <div className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-h-1 tw-overflow-hidden">
        <div
          className={cn(
            "tw-h-full tw-animate-progress-bar tw-rounded-r-[1px] tw-ease-linear",
            progressVariants({ variant }),
          )}
          style={{
            animationDuration: duration - 100 + "ms",
            animationPlayState: paused ? "paused" : "running",
            animationDirection: "reverse",
            animationFillMode: "forwards",
          }}
        />
      </div>
    </ToastPrimitives.Root>
  );
});
Toast.displayName = ToastPrimitives.Root.displayName;

const ToastIcon = ({ variant }: VariantProps<typeof iconVariants>) => {
  if (variant === "error") {
    return <X className={iconVariants({ variant })} />;
  }
  if (variant === "success") {
    return <Check className={iconVariants({ variant })} />;
  }
  return <span></span>;
};

const ToastClose = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Close>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Close
    ref={ref}
    className={cn(
      "tw-right-2 tw-top-2 tw-self-start tw-rounded-md tw-border-0 tw-bg-transparent tw-p-1 tw-leading-4 tw-text-grey-500 hover:tw-bg-grey-15 hover:tw-text-primary focus:tw-outline-none focus:tw-ring-2",
      className,
    )}
    toast-close=""
    {...props}
  >
    <X className="tw-h-4 tw-w-4" />
  </ToastPrimitives.Close>
));
ToastClose.displayName = ToastPrimitives.Close.displayName;

const ToastTitle = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Title>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Title
    ref={ref}
    className={cn("tw-text-sm tw-font-semibold", className)}
    {...props}
  />
));
ToastTitle.displayName = ToastPrimitives.Title.displayName;

const ToastDescription = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Description>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Description
    ref={ref}
    className={cn("tw-text-sm tw-opacity-90", className)}
    {...props}
  />
));
ToastDescription.displayName = ToastPrimitives.Description.displayName;

type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>;

export {
  type ToastProps,
  ToastProvider,
  ToastViewport,
  Toast,
  ToastTitle,
  ToastDescription,
  ToastClose,
  getAddSuccessDescription,
  getRemoveSuccessDescription,
  getErrorDescription,
};
